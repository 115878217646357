<script>
//import moment from "moment";
/* import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header"; */

import DevicesData from "./devices-data";

import {
  layoutComputed,
  authComputed,
  devicesMethods,
  regionsMethods,
  provincesMethods,
  statesMethods,
  causesMethods,
  actionsMethods,
  vaccintypesMethods,
  statesComputed,
  causesComputed,
  actionsComputed,
  devicesComputed,
  regionsComputed,
  provincesComputed,
  vaccintypesComputed,
} from "@/state/helpers";
/**
 * Offline Devices component
 */
export default {
  /* page: {
    title: "Offline-Devices",
    meta: [{ name: "Offline-Devices" }],
  }, */
  props: ["alertsFilter", "operations", "perPage"],
  components: {
    /* Layout,
    PageHeader, */
    DevicesData,
  },
  mounted() {
    const filter = {}
    Object.keys(this.alertsFilter).forEach(key=>{
      if(this.alertsFilter[key] != null) filter[key] = this.alertsFilter[key]
    })
    this.retrieveDevices(filter).then(async () => {
      await this.retrieveRegions();
      await this.retrieveProvinces();
      if (this.getVaccintypes.results) {
        let vaccintypesData = this.getVaccintypes.results;
        vaccintypesData.map((obj) => {
          this.vaccintypesFilterData.push({ value: obj.id, text: obj.name });
        });
      } else await this.retrieveVaccinTypes();
    });
  },
  data() {
    return {
      title: "titles.offlineDevices.text",

      busy: true,

      currentPage: 1,
      //perPage: 100,
      pageOptions: [10, 25, 50, 100],

      loaderDashboard: false,

      devicesData: [],
      statesData: [],
      causesData: [],
      actionsData: [],

      typesFilterData: [
        { value: null, text: "--All--" },
        { value: 1, text: "REFRIGERATEUR" },
        { value: 2, text: "CONGELATEUR" },
        { value: 3, text: "ARMOIRES_FRIGORIFIQUE" },
        { value: 4, text: "CHAMBRE_FROIDE" },
        { value: 5, text: "CAMION" },
      ],
      stockTypesFilterData: [
        { value: null, text: "--ALL--" },
        { value: "24 H", text: "24 H" },
        { value: "VIDE", text: "VIDE" },
      ],
      vaccintypesFilterData: [{ value: null, text: "--All--" }],
      provincesData: null,
      region: null,
      regionsFilterData: [{ value: null, text: "--ALL--" }],
      provincesFilterData: [{ value: null, text: "--ALL--" }],

      devicesFilter: {
        serial: null,
        ref: null,
        name: null,
        type: null,
        stockType: null,
        region: null,
        province: null,
        vaccinTypes: null,
      },
      errFilter: null,
      searchStatus: false,

      vaccintypes: [null],

      dates: [],
      fromTime: "00:00",
      toTime: "00:00",
      fromContext: null,
      toContext: null,
      minDate: "2000-01-01",
      menu: false,

      exportStatus: false,
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...devicesComputed,
    ...regionsComputed,
    ...provincesComputed,
    ...statesComputed,
    ...causesComputed,
    ...actionsComputed,
    ...vaccintypesComputed,

    dateRangeText() {
      if (this.dates.length > 0) {
        return this.dates.join(" ~ ");
      } else {
        return "Export XLS !";
      }
    },

    items() {
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.offlineDevices.text"),
          active: true,
        },
      ];
    },
  },
  methods: {
    ...devicesMethods,
    ...regionsMethods,
    ...provincesMethods,
    ...statesMethods,
    ...causesMethods,
    ...actionsMethods,
    ...vaccintypesMethods,

    retrieveDevices({ perPage, page, serial, name, ref, type, regions, provinces, stockType, vaccintypes }) {
      let params = {
        page: page ? page : this.currentPage,
        limit: perPage ? perPage : this.perPage,
        serial: serial ? serial : this.alertsFilter.serial,
        ref: ref ? ref : this.alertsFilter.ref,
        name: name ? name : this.alertsFilter.name,
        type: type ? type : this.alertsFilter.type,
        regions: regions ? regions : this.alertsFilter.regions,
        provinces: provinces ? provinces : this.alertsFilter.provinces,
        stockType: stockType ? stockType : this.alertsFilter.stockType,
        vaccinTypes: vaccintypes ? vaccintypes : this.alertsFilter.vaccinTypes,
        or: [{offline: true}, {extV: 0}],
        //offline: true,
        sortBy: "disconnectedAt:desc",
      };
      let paramsFilter = this.cleanObject(params);
      this.busy = true;
      return this.getDevicesBy(paramsFilter).then((devices) => {
        this.busy = false;
        return devices;
      });
    },
    retrieveStates() {
      if (this.getStates.results) this.statesData = this.getStates.results;
      else this.getStatesBy({ limit: 100 });
    },
    retrieveCauses() {
      this.getCausesBy({ limit: 100 });
    },
    retrieveActions() {
      if (this.getActions.results) this.actionsData = this.getActions.results;
      else this.getActionsBy({ limit: 100 });
    },
    retrieveRegions() {
      if (this.getRegions.results) this.chargeRegions(this.getRegions.results);
      else
        return this.getRegionsBy({ limit: 100 }).then((regions) => {
          return regions;
        });
    },
    retrieveProvinces() {
      if (this.getProvinces.results)
        this.provincesData = this.getProvinces.results;
      else
        return this.getProvincesBy({ limit: 100 }).then((provinces) => {
          return provinces;
        });
    },
    retrieveVaccinTypes() {
      return this.getVaccintypesBy({ limit: 100 }).then((vaccintypes) => {
        return vaccintypes;
      });
    },
    chargeRegions(regions) {
      let rData = regions;
      rData.map((obj) => {
        this.regionsFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    chargeProvinces(region) {
      this.provincesFilterData = [{ value: null, text: "--ALL--" }];
      let provinces = this.provincesData;
      provinces.map((obj) => {
        if (obj.region == region)
          this.provincesFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    showModalSearch() {
      this.$bvModal.show("search-modal");
    },
    hideModalSearch() {
      this.$bvModal.hide("search-modal");
    },
    cancel() {
      return;
    },
    search() {
      /* if (!this.devicesFilter.serial && !this.devicesFilter.name)
        this.errFilter = this.$t("msgError.searchSerialOrName.text");
      else this.errFilter = ""; */
      if (!this.errFilter) {
        this.currentPage = 1;
        this.retrieveDevices({ ...this.alertsFilter, page: 1 }).then(
          (devices) => {
            this.searchStatus = true;
            if (devices.results.length < 1) {
              this.makeToast("Search...", "Device not found !", "danger");
            } else {
              return;
            }
          }
        );
        this.hideModalSearch();
      }
    },
    clearSearch() {
      this.devicesFilter = {
        serial: null,
        type: null,
        name: null,
        stockType: null,
        regions: null,
        provinces: null,
        vaccinTypes: null,
      };
      this.currentPage = 1;
      this.vaccintypes = [null];
      this.retrieveDevices({ ...this.alertsFilter, page: 1 }).then(() => {
        this.searchStatus = false;
      });
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.retrieveDevices({ ...this.alertsFilter });
    },
    cleanObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          delete obj[key];
        }
      });
      return obj;
    },
    async exportData() {
      if (this.dates.length == 2) {
        let from = `${this.dates[0]} ${this.fromTime}`;
        let to = `${this.dates[1]} ${this.toTime}`;
        let params = {
          from: from,
          to: to,
        };
        if (this.dates[0] == this.dates[1]) {
          let fromHoure = this.fromContext.hours;
          let fromMinute = this.fromContext.minutes;
          let toHoure = this.toContext.hours;
          let toMinute = this.toContext.minutes;
          if (toHoure >= fromHoure) {
            if (
              toHoure > fromHoure ||
              (toHoure == fromHoure && toMinute >= fromMinute)
            ) {
              this.$refs.menu.save(this.dates);
              this.exportStatus = true;
              await this.exportDevicesXLS(params)
                .then((res) => {
                  if (res.status) this.exportStatus = false;
                  else {
                    this.exportStatus = false;
                    this.makeToast(
                      "Export",
                      this.$t("msgError.exportEmpty.text"),
                      "danger"
                    );
                  }
                })
                .catch(() => {
                  this.exportStatus = false;
                });
              this.dates = [];
              this.$refs.menu.save(this.dates);
            } else {
              this.makeToast(
                "Date",
                this.$t("msgError.periodDate.text"),
                "danger"
              );
            }
          } else {
            this.makeToast(
              "Date",
              this.$t("msgError.periodDate.text"),
              "danger"
            );
          }
        } else {
          this.$refs.menu.save(this.dates);
          await this.exportDevicesXLS(params)
            .then((res) => {
              if (res.status) this.exportStatus = false;
              else {
                this.exportStatus = false;
                this.makeToast(
                  "Export",
                  this.$t("msgError.exportEmpty.text"),
                  "danger"
                );
              }
            })
            .catch(() => {
              this.exportStatus = false;
            });
          this.dates = [];
          this.$refs.menu.save(this.dates);
        }
      }
    },
    onFromContext(ctx) {
      this.fromContext = ctx;
    },
    onToContext(ctx) {
      this.toContext = ctx;
    },
  },
  watch: {
    getDevices(newVal) {
      this.devicesData = newVal.results;
      /* const devicesBrt = newVal.results;
      this.selectedDevices = [];
      const refs = {}
      let devicesListCustom = []
      devicesBrt.forEach((obj) => {
        if ( !refs[obj.ref] ){
          let vaccinTypes = obj.vaccinTypes.map(vaccin=> {return vaccin.name});
          refs[obj.ref] = {
            ...obj,
            date: obj.disconnectedAt ? moment(obj.disconnectedAt).format("DD-MM-YYYY HH:mm:ss") : "-",
            region: obj.region.name,
            province: obj.province.name,
            vaccinTypes: vaccinTypes ? vaccinTypes.toString() : "-"
          };
          devicesListCustom.push(refs[obj.ref])
        }
      });
      //this.nbAlerts[this.selectedTypeAlert] = alertsListCustom.length
      this.devicesData = devicesListCustom */
    },
    getStates(newVal) {
      this.statesData = newVal.results;
    },
    getCauses(newVal) {
      this.causesData = newVal.results;
    },
    getActions(newVal) {
      this.actionsData = newVal.results;
    },
    getRegions(newVal) {
      this.chargeRegions(newVal.results);
    },
    getProvinces(newVal) {
      this.provincesData = newVal.results;
      //this.chargeProvinces(newVal.results);
    },
    getVaccintypes(newVal) {
      console.log(newVal);
      let vaccintypesData = newVal.results;
      vaccintypesData.map((obj) => {
        this.vaccintypesFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    perPage(newVal) {
      this.retrieveDevices({ ...this.alertsFilter, perPage: newVal, page: 1 });
    },
    dates(newVal) {
      if (newVal.length == 1) {
        this.minDate = newVal[0];
      } else {
        this.minDate = "2000-01-01";
      }
    },
    region(newVal) {
      Object.assign(this.devicesFilter, { region: newVal, province: null });
      this.chargeProvinces(newVal);
    },
    vaccintypes(newVal) {
      let lenVaccintypes = newVal.length;

      if (!newVal[lenVaccintypes - 1] && lenVaccintypes > 1) {
        this.vaccintypes = [null];
        this.devicesFilter.vaccintypes = null;
      } else if (newVal[lenVaccintypes - 1] && newVal.includes(null)) {
        var filteredVaccintypes = this.vaccintypes.filter(function (item) {
          return item != null;
        });
        this.vaccintypes = filteredVaccintypes;
      } else if (newVal[lenVaccintypes - 1] && !newVal.includes(null)) {
        this.devicesFilter.vaccintypes = newVal;
      } else if (newVal.length == 0) {
        this.vaccintypes = [null];
        this.devicesFilter.vaccintypes = null;
      }
    },
    alertsFilter(newVal){
      this.devicesFilter = {
        ...this.devicesFilter,
        serial: newVal.serial,
        ref: newVal.ref,
        name: newVal.name,
        stockType: newVal.stockType,
        regions: newVal.regions,
        provinces: newVal.provinces,
        vaccinTypes: newVal.vaccinTypes,
      }
      const filter = {}
      Object.keys(newVal).forEach(key=>{
        if(newVal[key] != null) filter[key] = newVal[key]
      })
      this.retrieveDevices(filter)
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="$t(title)" :items="items" /> -->
    <div class="row">
      <div class="col-8 col-lg-8 d-inline-flex align-items-center">
         <!--<div class="mr-2 font-weight-bold">
          {{ $t("labels.numberDevices.text") }}
        </div> 
        <b-form-select
          style="max-width: 60px"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
        ></b-form-select>-->
      </div>
      <div class="col-4 col-lg-4 text-right" data-app>
        <div
          v-if="exportStatus == true"
          class="d-flex justify-content-center align-items-center mt-2"
          style="position: relative; z-index: 200"
        >
          <b-spinner label="Loading..." variant="primary"></b-spinner>
        </div>
        <v-menu
          v-if="exportStatus == false"
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              max
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            no-title
            range
            scrollable
            color="#e52342"
            :min="minDate"
          >
            <div class="row">
              <div class="col-6 d-flex">
                <b-time
                  hide-header
                  locale="fr"
                  v-model="fromTime"
                  style="font-size: 4px"
                  @context="onFromContext"
                ></b-time>
              </div>
              <div class="col-6 d-flex">
                <b-time
                  hide-header
                  locale="fr"
                  v-model="toTime"
                  style="font-size: 4px"
                  @context="onToContext"
                ></b-time>
              </div>
              <div class="col-12 text-left">
                <b-button class="mb-1" variant="success" @click="exportData()">
                  {{ $t("buttons.exportXLS.text") }}
                </b-button>
              </div>
            </div>
          </v-date-picker>
        </v-menu>
      </div>
      <!--<div class="col-3 col-lg-2 text-right">
         <b-form-input
          id="stateSh"
          v-model="devicesFilter.serial"
          :placeholder="$t('textInput.enterSerial.text')"
          class="mr-2"
          :state="errFilter ? false : null"
        ></b-form-input>
        <b-form-invalid-feedback style="position: absolute; top: 80%">
          {{ errFilter }}
        </b-form-invalid-feedback>
        <b-form-input
          id="stateSh"
          v-model="devicesFilter.name"
          :placeholder="$t('textInput.enterName.text')"
          class="mr-2"
          :state="errFilter ? false : null"
        ></b-form-input>
        <b-button type="button" class="mr-2" variant="secondary" @click="search"
          >{{$t("buttons.search.text")}}
        </b-button>
        <b-button
          v-if="searchStatus"
          type="button"
          class="mr-2"
          variant="warning"
          @click="clearSearch"
          ><i class="ri-refresh-line"></i>
        </b-button> 

        
        <b-button variant="light" @click="showModalSearch">
          <i class="ri-filter-3-line"></i>
        </b-button>
        <b-button
          v-if="searchStatus"
          type="button"
          class="mr-2"
          variant="warning"
          @click="clearSearch"
          ><i class="ri-refresh-line"></i>
        </b-button> 
        <b-modal
          id="search-modal1"
          ref="search-modal1"
          size="lg"
          :title="this.$t('modals.filterDevice.text')"
        >
          
          <div class="row">
            <div class="col-6 col-lg-6">
              <label for="stateSh">Serial</label>
              <b-form-input
                id="stateSh"
                v-model="devicesFilter.serial"
                placeholder="Enter serial"
                class="mb-1"
              ></b-form-input>
            </div>
            
            <div class="col-6 col-lg-6">
              <label for="nameSh">{{ $t("modals.name.text") }}</label>
              <b-form-input
                id="nameSh"
                v-model="devicesFilter.name"
                :placeholder="$t('modals.name.text')"
                class="mb-1"
              ></b-form-input>
            </div>
          </div>
          
          <div class="row">
            <div class="col-6 col-lg-6">
              <label for="reference">{{ $t("modals.reference.text") }}</label>
              <b-form-input
                id="reference"
                v-model="devicesFilter.ref"
                class="mb-1"
                :placeholder="$t('modals.reference.text')"
              ></b-form-input>
            </div>
            <div class="col-6 col-lg-6">
              <label for="typeSh">{{ $t("modals.type.text") }}</label>
              <b-form-select
                id="typeSh"
                v-model="devicesFilter.type"
                :options="typesFilterData"
                class="mb-1"
              ></b-form-select>
            </div>
          </div>
          
          <div class="row">
            <div class="col-6 col-lg-6" data-app>
              <label for="vaccinSh">{{ $t("modals.vaccinType.text") }}</label>
              <v-select
                id="vaccinSh"
                v-model="vaccintypes"
                :items="vaccintypesFilterData"
                :menu-props="{ maxHeight: '200' }"
                item-text="text"
                item-value="value"
                multiple
              ></v-select>
            </div>
            <div class="col-6 col-lg-6" data-app>
              <label for="stypeSh">{{ $t("modals.stockType.text") }}</label>
              <v-select
                id="stypeSh"
                v-model="devicesFilter.stockType"
                :items="stockTypesFilterData"
                :menu-props="{ maxHeight: '200' }"
                item-text="text"
                item-value="value"
              ></v-select>
            </div>
          </div>
          
          <div class="row">
            <div class="col-6 col-lg-6" data-app>
              <label for="regionSh">{{ $t("modals.region.text") }}</label>
              <v-select
                id="regionSh"
                v-model="region"
                :items="regionsFilterData"
                :menu-props="{ maxHeight: '200' }"
                item-text="text"
                item-value="value"
              ></v-select>
            </div>
            
            <div class="col-6 col-lg-6" data-app>
              <label for="provinceSh">{{ $t("modals.province.text") }}</label>
              <v-select
                id="provinceSh"
                v-model="devicesFilter.province"
                :items="provincesFilterData"
                :menu-props="{ maxHeight: '200' }"
                item-text="text"
                item-value="value"
              ></v-select>
            </div>
          </div>

          <template #modal-footer="{ cancel }">
            <div class="row p-2">
              <div class="col-10 text-right p-1">
                <b-button variant="success" class="text-white" @click="search">
                  {{ $t("buttons.filter.text") }}
                </b-button>
              </div>
              <div class="col-2 text-center p-1">
                <b-button @click="cancel()">
                  {{ $t("buttons.cancel.text") }}
                </b-button>
              </div>
            </div>
          </template>
        </b-modal>
        
      </div>-->
    </div>
    <div class="row">
      <div class="col-12">
        <DevicesData
          v-bind:devices="devicesData"
          :states="statesData"
          :causes="causesData"
          :actions="actionsData"
          :operations="operations"
          :busy="busy"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="dataTables_paginate paging_simple_numbers float-right mb-3">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="getDevices.totalResults"
              :per-page="perPage"
              @change="handlePageChange"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>