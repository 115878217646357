<script>
/**
 * Devices Data component
 */
import moment from "moment";
import { devicesMethods, devicesComputed, authComputed } from "@/state/helpers";
import Intervention from "./intervention";
export default {
  props: ["devices", "causes", "actions", "states", "operations", "busy", "loaderStatus"],
  components: {
    Intervention,
  },
  data() {
    return {
      device: {},
      devicesList: [],
      deviceTypes: {
        1: "REFRIGERATEUR",
        2: "CONGELATEUR",
        3: "ARMOIRES_FRIGORIFIQUE",
        4: "CHAMBRE_FROIDE",
        5: "CAMION",
      },
      deviceInterv: null,
      deviceAquit: {
        operation: null,
        note: null
      },
      err: {
        operation: null,
        note: null
      },
      operationsData: [{ value: null, text: "Operation", disabled: true }],
    };
  },
  computed: {
    ...authComputed,
    ...devicesComputed,
    fieldsComputed() {
      if (this.loggedIn.user.role != "USER") {
        return [
          { key: "serial", sortable: false, label: "Serial" },
          {
            key: "date",
            sortable: false,
            label: this.$t("dataTable.dateDisconnection.text"),
          },
          {
            key: "ref",
            sortable: false,
            label: this.$t("dataTable.reference.text"),
          },
          {
            key: "name",
            sortable: false,
            label: this.$t("dataTable.name.text"),
          },
          {
            key: "region",
            sortable: false,
            label: this.$t("dataTable.region.text"),
          },
          {
            key: "province",
            sortable: false,
            label: this.$t("dataTable.province.text"),
          },
          {
            key: "stockType",
            sortable: false,
            label: this.$t("dataTable.stockType.text"),
          },
          {
            key: "vaccinTypes",
            sortable: false,
            text: this.$t("dataTable.vaccinType.text"),
          },
          {
            key: "type",
            sortable: false,
            label: this.$t("dataTable.type.text"),
          },
          {
            key: "interventionState",
            sortable: false,
            label: "intervention",
          },
          {
            key: "offline",
            sortable: false,
            label: this.$t("dataTable.status.text"),
          },
          {
            key: "extV",
            sortable: false,
            label: this.$t("dataTable.battery.text"),
          },
          /* {
            key: "actions",
            sortable: false,
            label: this.$t("dataTable.tools.text"),
            class: "tdClass",
          }, */
        ];
      } else {
        return [
          { key: "serial", sortable: false, label: "Serial" },
          {
            key: "date",
            sortable: false,
            label: this.$t("dataTable.dateDisconnection.text"),
          },
          {
            key: "ref",
            sortable: false,
            label: this.$t("dataTable.reference.text"),
          },
          {
            key: "name",
            sortable: false,
            label: this.$t("dataTable.name.text"),
          },
          {
            key: "region",
            sortable: false,
            label: this.$t("dataTable.region.text"),
          },
          {
            key: "province",
            sortable: false,
            label: this.$t("dataTable.province.text"),
          },
          {
            key: "stockType",
            sortable: false,
            label: this.$t("dataTable.stockType.text"),
          },
          {
            key: "vaccinTypes",
            sortable: false,
            text: this.$t("dataTable.vaccinType.text"),
          },
          {
            key: "type",
            sortable: false,
            label: this.$t("dataTable.type.text"),
          },
          {
            key: "interventionState",
            sortable: false,
            label: "intervention",
          },
          {
            key: "offline",
            sortable: false,
            label: this.$t("dataTable.status.text"),
          },
          {
            key: "extV",
            sortable: false,
            label: this.$t("dataTable.battery.text"),
          },
        ];
      }
    },
  },
  mounted() {
    let operationsD = this.operations;
      operationsD.filter(obj => obj.type === 3).map((obj) => {
        this.operationsData.push({ value: obj.id, text: obj.name });
      });
  },
  methods: {
    ...devicesMethods,
    showModalAquit() {
      this.$bvModal.show("intervention-modal");
    },
    hideModalAquit() {
      this.$bvModal.hide("intervention-modal");
    },
    inter(intervention) {
      let interventionFilter = this.cleanObject(intervention);
      this.interventionOffline(interventionFilter).then((device) => {
        this.makeToast("Intervention", device.name, "success")
        this.hideModalAquit();
      });
    },
    passDevice(device) {
      this.device = device;
      this.showModalAquit();
    },
    cleanObject(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    openStateModal(device) {
      this.deviceInterv = device
      this.$bvModal.show("deviceInterv-modal");
    },
    hideStateModal() {
      this.$bvModal.hide("deviceInterv-modal");
    },
    async changeIntervState(ok){
      //await this.updateDevice({deviceId: deviceInterv.id, interventionState: !deviceInterv.interventionState})
      if (!this.deviceAquit.operation)
        this.err.operation = this.$t("msgError.obligField.text");
      else this.err.operation = "";
      if(!this.err.operation){
        await this.aquitDevice({serial: this.deviceInterv.serial, ...this.deviceAquit})
        ok()
        this.hideStateModal()
      }
    },
    rowClicked(record) {
      this.$router.push({
        name: "device",
        params: { serial: record.serial },
      });
    },
  },
  watch: {
    devices(newVal) {
      this.devicesList = [];
      //this.devicesList = newVal
      newVal.map((obj) => {
        let vaccinTypes = obj.vaccinTypes.map(vaccin=> {return vaccin.name});
        this.devicesList.push({
          ...obj,
          date: obj.disconnectedAt ? moment(obj.disconnectedAt).format("DD-MM-YYYY HH:mm:ss") : "-",
          region: obj.region.name,
          province: obj.province.name,
          vaccinTypes: vaccinTypes ? vaccinTypes.toString() : "-"
        });
      });
    },
  },
};
</script>

<template>
  <div
    class="table-responsive mb-3 bg-white rounded-lg"
    style="min-height: 430px"
  >
    <b-table
      class="mb-0"
      hover
      show-empty
      centred
      :busy="busy"
      :empty-text="$t('dataTable.noDevices.text')"
      :items="devicesList"
      :fields="fieldsComputed"
      v-bind:style="{ cursor: `pointer` }"
      stacked="sm"
      @row-clicked="rowClicked"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <strong>{{ $t("dataTable.loading.text") }}</strong>
        </div>
      </template>
      <template v-slot:cell(type)="row">
        <div class="font-size-14 font-weight-bold">
          {{deviceTypes[row.item.type]}}
        </div>
      </template>
      <template v-slot:cell(interventionState)="row">
        <div class="text-center">
          <b-button :variant="
              row.item.interventionState === 3 ? 'success' 
              : row.item.interventionState === 2 ? 'warning' 
              :row.item.interventionState === 4 ? 'dark' 
              : 'danger'"  
            @click="openStateModal(row.item)">
          </b-button>
        </div>
      </template>
      <template v-slot:cell(offline)="row">
        <div class="font-size-14 text-center font-weight-bold">
          <i
            v-if="row.item.offline == false"
            class="text-success ri-checkbox-blank-circle-fill"
          ></i>
          <i
            v-if="row.item.offline == true || row.item.offline == null"
            class="text-secondary ri-checkbox-blank-circle-fill"
          ></i>
        </div>
      </template>
      <template v-slot:cell(extV)="row">
        <div class="text-center">
          <p
            v-if="row.item.extV === 0"
            style="display: inline-block; font-size: x-large;"
            :style="{
              'color': row.item.offline === true ? 'grey' : 'green'
            }"
          >
            <i class="ri-battery-charge-line"></i>
          </p>
        </div>
      </template>
      <!-- <template v-slot:cell(actions)="row">
        <div class="text-center">
          <b-button
            type="button"
            class="mr-2"
            variant="dark"
            >{{ $t("buttons.aquit.text") }}
          </b-button>
        </div>
      </template> -->
      <!-- <template v-slot:cell(actions)="data">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <b-button
              type="button"
              class="ml-2"
              variant="secondary"
              @click="passDevice(data.item)"
              >Intervention
            </b-button>
          </div>
        </div>
      </template> -->
    </b-table>
    <b-modal 
      id="deviceInterv-modal"
      ref="deviceInterv-modal"
      size="md"
      title="confirmation"
    >
      <!-- Operations -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="operations">Operations</label>
          <b-form-select
            id="operations"
            v-model="deviceAquit.operation"
            :options="operationsData"
            class="mb-1"
            :state="err.operation ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.operation }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="note">{{$t("modals.note.text")}}</label>
          <b-form-input
            id="note"
            v-model="deviceAquit.note"
            class="mb-1"
            :placeholder="$t('modals.note.text')"
            :state="err.note ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.note }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <template #modal-footer="{ ok }">
        <div class="text-center">
          <b-button variant="success" @click="changeIntervState(ok)">
            {{ $t("buttons.valid.text") }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <Intervention
      v-bind:device="device"
      :causes="causes"
      :actions="actions"
      :states="states"
      @validIntervention="inter"
    />
  </div>
</template>
<style>
.tdClass {
  text-align: center;
}
</style>