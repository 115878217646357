<template>
  <div>
    <b-modal
      id="intervention-modal"
      ref="intervention-modal"
      size="md"
      @hidden="cancel"
      title="Intervention"
    >
      <!-- Status -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="state">{{$t('modals.status.text')}}</label>
          <b-form-select
            id="state"
            v-model="intervention.state"
            :options="alertStates"
            class="mb-1"
            :state="err.state ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.state }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <!-- Actions -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="actions">{{$t('modals.actions.text')}}</label>
          <b-form-select
            id="actions"
            v-model="intervention.action"
            :options="alertActions"
            class="mb-1"
            :state="err.action ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.action }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <!-- Causes -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="causes">{{$t('modals.causes.text')}}</label>
          <b-form-select
            id="causes"
            v-model="intervention.cause"
            :options="alertCauses"
            class="mb-1"
            :state="err.cause ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.cause }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- savedDoses -->
      <div class="row">
        <div class="col-6">
          <label for="savedDosesSino">{{$t('modals.savedDosesSino.text')}}</label>
          <b-form-input
            id="savedDosesSino"
            type="number"
            min="0"
            v-model="intervention.savedDosesSino"
            :placeholder="this.$t('textInput.enterSavedDosesSino.text')"
            class="mb-1"
            :state="err.savedDosesSino ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.savedDosesSino }}
          </b-form-invalid-feedback>
        </div>
        <div class="col-6">
          <label for="savedDosesFizer">{{$t('modals.savedDosesFizer.text')}}</label>
          <b-form-input
            id="savedDosesFizer"
            type="number"
            min="0"
            v-model="intervention.savedDosesFizer"
            :placeholder="this.$t('textInput.enterSavedDosesFizer.text')"
            class="mb-1"
            :state="err.savedDosesFizer ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.savedDosesFizer }}
          </b-form-invalid-feedback>
        </div>
      </div>

      
      <!-- Note -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="note">{{$t('modals.note.text')}}</label>
          <b-form-textarea
            id="note"
            :rows="5"
            v-model="intervention.note"
            :placeholder="this.$t('textInput.enterNote.text')"
            class="mb-1"
            :state="err.note ? false : null"
          ></b-form-textarea>
          <b-form-invalid-feedback>
            {{ err.note }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="aquit(ok)">
              Valider
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()"> {{$t('buttons.cancel.text')}} </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["device", "states", "actions", "causes"],

  created() {},
  data() {
    return {
      alertStates: [{ value: null, text: "Status", disabled: true }],
      alertActions: [{ value: null, text: "Action", disabled: true }],
      alertCauses: [{ value: null, text: "Cause", disabled: true }],
      intervention: {
        deviceId: null,
        state: null,
        action: null,
        cause: null,
        note: null,
        savedDosesSino: null,
        savedDosesFizer: null,
      },
      err: {
        deviceId: null,
        state: null,
        action: null,
        cause: null,
        note: null,
        savedDosesSino: null,
        savedDosesFizer: null,
      },
    };
  },
  watch: {
    device(newVal) {
      this.intervention.deviceId = newVal.id;
    },
    states(newVal) {
      let statesData = newVal;
      statesData.map((obj) => {
        if (obj.isClosed == true)
          this.alertStates.push({ value: obj.id, text: obj.name });
      });
    },
    causes(newVal) {
      let causesData = newVal;
      causesData.map((obj) => {
        this.alertCauses.push({ value: obj.id, text: obj.name });
      });
    },
    actions(newVal) {
      let actionsData = newVal;
      actionsData.map((obj) => {
        this.alertActions.push({ value: obj.id, text: obj.name });
      });
    },
  },
  methods: {
    cancel() {
      this.clear()
    },
    clear() {
      this.intervention = {
        deviceId: null,
        state: null,
        action: null,
        cause: null,
        note: null,
        savedDosesSino: null,
        savedDosesFizer: null,
      };
      this.err = {
        deviceId: null,
        state: null,
        action: null,
        cause: null,
        note: null,
        savedDosesSino: null,
        savedDosesFizer: null,
      };
    },
    cleanObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined) {
          delete obj[key];
        }
      });
      return obj;
    },
    aquit(ok) {
      if (!this.intervention.state)
        this.err.state = this.$t("msgError.obligField.text");
      else {
        this.err.state = "";
        let state = this.states.filter(
          (obj) => obj.id == this.intervention.state
        );
        if (!this.intervention.action && state[0].isClosed)
          this.err.action = this.$t("msgError.obligField.text");
        else this.err.action = "";

        if (!this.intervention.cause && state[0].isClosed)
          this.err.cause = this.$t("msgError.obligField.text");
        else this.err.cause = "";
      }

      if (!this.err.state && !this.err.action && !this.err.cause) {
          ok()
           let inter = {
              deviceId: this.intervention.deviceId,
              state: this.intervention.state ? this.intervention.state : null,
              action: this.intervention.action ? this.intervention.action : null,
              cause: this.intervention.cause ? this.intervention.cause : null,
              note: this.intervention.note ? this.intervention.note : null,
              savedDosesSino: this.intervention.savedDosesSino ? this.intervention.savedDosesSino : null,
              savedDosesFizer: this.intervention.savedDosesFizer ? this.intervention.savedDosesFizer : null,
          }
          let filtredIntervention = this.cleanObject(inter)
          this.$emit("validIntervention", filtredIntervention);
      }
    },
  },
};
</script>